import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import { connect } from "react-redux";
import logo from "../../logo.png";
import notFound from "../../404.jpg";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Assurez-vous d'importer FontAwesomeIcon si vous l'utilisez.
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";



import {
	Navbar,
	Nav,

} from "reactstrap";

const Navigation = props => {
	const { auth, profile } = props;
	const [isOpen, setIsOpen] = useState(true);
	const [adminMenuOpen, setAdminMenuOpen] = useState(false); // État pour le sous-menu "Admin"


	// Fonction pour basculer l'état du sous-menu "Admin"
	const toggleAdminMenu = () => {
		setAdminMenuOpen(!adminMenuOpen);
	};

	const location = useLocation();
	const { t } = useTranslation();

	const links = auth.uid ? (
		<SignedInLinks profile={profile} />
	) : (
		<SignedOutLinks />
	);


	return (
		<div>
			{location.pathname !== "/signin" && location.pathname !== "/signup"
				&& (location.pathname === "/meetings"
					|| location.pathname === "/Intact"
					|| location.pathname === "/coaches"
					|| location.pathname === "/sprint"
					|| location.pathname === "/attendance"
					|| location.pathname === "/sprintattendance"
					|| location.pathname.includes("/meeting/")
					|| location.pathname === "/administration"
					|| location.pathname === "/sprintAdministration"
					|| location.pathname === "/userManagement"
					|| location.pathname === "/confidence"
					|| location.pathname === "/resources"
					|| location.pathname === "/masterminds"
					|| location.pathname === "/learningLogs"
					|| location.pathname === "/companiesAdministration"
					|| location.pathname === "/sprintPlanning"
					|| location.pathname === "/getHours"
					|| location.pathname === "/") && (
					<>
						<Navbar
							style={{ width: "100%", position: "fixed", zIndex: 11 }}
							color="light"
							light
							expand="xs"
						>
							<Link to="/" className="brand-logo">
								<img src={logo} width="200" alt="Ellipse" />
							</Link>
							<Nav className="ml-auto" navbar>
								{links}
							</Nav>
						</Navbar>
						<div
							style={{
								position: "relative",
								left: `${isOpen ? "240px" : "50px"}`,
								maxWidth: `calc(100% - ${isOpen ? "240px" : "50px"})`,
								overflow: "hidden",
								transition: ".5s",
								top: "112.5px",
							}}
						>
							{props.children}
						</div>

						<div
							style={{
								position: "fixed",
								background: "rgb(248, 249, 250)",
								left: "0",
								top: "112.5px",
								height: "calc(100% - 112.5px)",
								width: `${isOpen ? "240px" : "50px"}`,
								transition: ".5s",
								overflowY: "auto", // Rend le contenu du menu scrollable
							}}
						>
							{location.pathname !== "/Intact" && (
								<Nav
									vertical
									className={`${isOpen ? "not-collapsed" : "collapsed"}`}
								>
									<li className="custom-nav-item" key='1'>
										<a
											onClick={e => {
												e.preventDefault();
												setIsOpen(!isOpen);
											}}
											href="#a"
										>
											<i
												className={`icon-container fa ${isOpen ? "fa-close" : "fa-bars"
													}`}
											></i>
										</a>
									</li>
									<li className="custom-nav-item" title="Coaches" key='2'>
										<Link to="/coaches" >
											<i className={`icon-container fa fa-users`}></i>
											{t('navigation_my_coaches')}
										</Link>
									</li>
									<li className="custom-nav-item" title="Meetings">
										<Link to="/meetings" >
											<i
												className={`icon-container fa fa-calendar-check-o
									`}
											></i>
											{t('navigation_my_meeting')}
										</Link>
									</li>
									<li className="custom-nav-item" title="Sprint" key='3'>
										<Link to="/sprint" //onClick={() => setIsOpen(false)}
										>
											<i
												className={`icon-container fas fa-running`}
											></i>
											{t('navigation_sprint')}
										</Link>
									</li>
									<li className="custom-nav-item" title="My confidence" key='4'>
										<Link to="/confidence" >
											<i
												className={`icon-container fas fa-chart-bar`}
											></i>
											{t('navigation_my_confidence')}
										</Link>
									</li>

									{((props.profile?.accessToConfidenceLab === true) ||
										(props.profile?.role?.includes("admin") || props.profile?.role?.includes("ressourcesAdmin")) ||
										(props.profile?.numberHourBuy > 19)) && (
											<li className="custom-nav-item" title="Ressources">
												<Link to="/resources">
													<i className="icon-container fas fa-photo-video"></i>
													{t('navigation_resources')}
												</Link>
											</li>
										)}

									<li className="custom-nav-item" title="Attendance Record" key='5'>
										<Link to="/attendance" >
											<i
												className={`icon-container far fa-address-book`}
											></i>
											{t('navigation_my_attendance')}
										</Link>
									</li>
									<hr />
									{props.profile.role != null && (props.profile.role.indexOf("admin") > -1 || props.profile.role.indexOf("sprint") > -1
										|| props.profile.role.indexOf("account manager") > -1 || profile.role.indexOf("professor") > -1) && (
											<ul>
												<li
													className="custom-nav-item"
													title="Admin"
													key="6"
													onClick={toggleAdminMenu}

												>
													{adminMenuOpen ? (
														<>
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<i className={`icon-container fas fa-angle-down fa-2xs`} style={{ marginRight: '-5px' }}></i>
																Administration
															</div>
														</>) : (
														<>
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<i className={`icon-container fas fa-angle-right fa-2xs`} style={{ marginRight: '-5px' }}></i>
																Administration</div>
														</>
													)}


												</li>
												{adminMenuOpen && props.profile.role != null && (props.profile.role.indexOf("admin") > -1 || props.profile.role.indexOf("sprint") > -1) && (
													<li className="custom-nav-item" title="Sprint Attendance" style={{ paddingLeft: '20px' }}>
														<Link to="/sprintattendance" >
															<i
																className={`icon-container fas fa-chart-bar`}
															></i>
															{t('navigation_sprint_attendance')}
														</Link>
													</li>
												)}
												{adminMenuOpen && props.profile.role != null && (props.profile.role.indexOf("admin") > -1
													|| props.profile.role.indexOf("professor") > -1) && (
														<li className="custom-nav-item" title="learningLogs" style={{ paddingLeft: '20px' }}>
															<Link to="/learningLogs">
																<i
																	className={`icon-container fas fa-cog
								`}
																></i>
																Search members
															</Link>
														</li>
													)}
												{adminMenuOpen && props.profile.role != null && (props.profile.role.indexOf("admin") > -1 || props.profile.role.indexOf("account manager") > -1) && (
													<li className="custom-nav-item" title="User management" style={{ paddingLeft: '20px' }}>
														<Link to="/userManagement" >
															<i
																className={`icon-container fas fa-users-cog
								`}
															></i>
															{t('navigation_user_management')}
														</Link>
													</li>
												)}
												{adminMenuOpen && props.profile.role != null && (props.profile.role.indexOf("admin") > -1
													|| props.profile.role.indexOf("studentAdmin") > -1) && (
														<li className="custom-nav-item" title="Administration" style={{ paddingLeft: '20px' }}>
															<Link to="/administration">
																<i
																	className={`icon-container fas fa-cog
								`}
																></i>
																{t('navigation_administration')}
															</Link>
														</li>
													)}
												{adminMenuOpen && props.profile.role != null && (props.profile.role.indexOf("admin") > -1) && (
													<li className="custom-nav-item" title="Masterminds admin" style={{ paddingLeft: '20px' }}>
														<Link to="/masterminds" >
															<i
																className={`icon-container fas fa-cog
								`}
															></i>
															{t('navigation_masterminds_administration')}
														</Link>
													</li>
												)}
												{adminMenuOpen && props.profile.role != null && ((props.profile.role.indexOf("admin") > -1 || profile.role.indexOf("sprint") > -1)) && (
													<li className="custom-nav-item" title="Sprint admin" style={{ paddingLeft: '20px' }}>
														<Link to="/sprintAdministration" >
															<i
																className={`icon-container fas fa-cog
								`}
															></i>
															{t('navigation_sprint_administration')}
														</Link>
													</li>
												)}
												{adminMenuOpen && props.profile.role != null && ((props.profile.role.indexOf("admin") > -1 || profile.role.indexOf("sprint") > -1)) && (
													<li className="custom-nav-item" title="Sprint admin" style={{ paddingLeft: '20px' }}>
														<Link to="/sprintPlanning" >
															<i
																className={`icon-container fas fa-cog
								`}
															></i>
															Sprint Planning
														</Link>
													</li>
												)}
												{adminMenuOpen && props.profile.role != null && (props.profile.role.indexOf("admin") > -1) && (
													<li className="custom-nav-item" title="Sprint admin" style={{ paddingLeft: '20px' }}>
														<Link to="/companiesAdministration" >
															<i
																className={`icon-container fas fa-cog
								`}
															></i>
															{t('navigation_companies_administration')}
														</Link>
													</li>
												)}
												{adminMenuOpen && props.profile.role != null && (props.profile.role.indexOf("admin") > -1) && (
													<li className="custom-nav-item" title="Sprint admin" style={{ paddingLeft: '20px' }}>
														<Link to="/companiesAdministration" >
															<i
																className={`icon-container fas fa-cog
								`}
															></i>
															{t('navigation_get_hour')}
														</Link>
													</li>
												)}
											</ul>

										)}


								</Nav>
							)}
						</div>
					</>
				)}

			{location.pathname !== "/signin"
				&& location.pathname !== "/signup"
				&& location.pathname !== "/meetings"
				&& location.pathname.indexOf("/meeting") === -1
				&& location.pathname !== "/sprintattendance"
				&& location.pathname !== "/Intact"
				&& location.pathname !== "/attendance"
				&& location.pathname !== "/administration"
				&& location.pathname !== "/coaches"
				&& location.pathname !== "/sprint"
				&& location.pathname !== "/userManagement"
				&& location.pathname !== "/sprintAdministration"
				&& location.pathname !== "/companiesAdministration"
				&& location.pathname !== "/getHours"
				&& location.pathname !== "/confidence"
				&& location.pathname !== "/resources"
				&& location.pathname !== "/masterminds"
				&& location.pathname !== "/learningLogs"
				&& location.pathname !== "/sprintPlanning"
				&& location.pathname !== "/" && (
					<>
						<center>
							<img src={notFound} alt="Not found" />
						</center>
					</>
				)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auth: state.firebase.auth,
		profile: state.firebase.profile,
		sprints: state.firestore.ordered.sprints,
		sprintsHoliday: state.firestore.ordered.sprintsHoliday,
		roles: state.firestore.ordered.roles,
	};
};

export default compose(connect(mapStateToProps),
	firestoreConnect([{ collection: "bootCamp" }, { collection: "sprints" }, { collection: "sprintsHoliday" }, { collection: "roles" }]))
	(Navigation);

